<template>

    <div class="inline-message-wrapper">
        <div class="inline-message" :class="this.type" :id="this.elemId">

            <span class="inline-message-icon" v-if="this.showIcon === true && this.type === 'success'">
                <i class="fa-solid fa-circle-check"></i>
            </span>

            <span class="inline-message-icon" v-if="this.showIcon === true && this.type === 'info'">
                <i class="fa-solid fa-circle-info"></i>
            </span>

            <span class="inline-message-icon" v-if="this.showIcon === true && this.type === 'warning'">
                <i class="fa-solid fa-circle-exclamation"></i>
            </span>

            <span class="inline-message-icon" v-if="this.showIcon === true && this.type === 'error'">
                <i class="fa-solid fa-triangle-exclamation"></i>
            </span>

            <span id="message">
                {{ this.message }}
            </span>

        </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'InlineMessage',

    props:
    {
        showIcon:
        {
            type: Boolean,
            required: false,
            default: true
        },

        message:
        {
            type: String,
            required: true,
            default: ''
        },

        elemId:
        {
            type: String,
            required: false,
            default: ''
        },

        /**
         * can be either success, info, warning or error
         */
        type:
        {
            type: String,
            required: false,
            default: 'warning'
        }
    },
});
</script>


<style scoped>
.inline-message-wrapper
{
    background-color: white;
    overflow: hidden;
}

.inline-message
{
    font-family: 'Open Sans', sans-serif;
    font-family: 'Ubuntu', sans-serif;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 15px;
    color: rgb(255, 152, 17);
    background-color: rgba(255, 152, 17, 0.2);
    border-left: 3px solid rgb(255, 152, 17);
    text-align: center;
}

.inline-message-icon
{
    font-size: 2.4vh;
}

.inline-message.error
{
    color: rgb(250, 7, 7);
    background-color: rgba(250, 7, 7, 0.2);
    border-left: 3px solid rgb(255, 7, 7);
}

.inline-message.success
{
    color: rgb(63, 141, 0);
    background-color: rgba(63, 141, 0, 0.2);
    border-left: 3px solid rgb(63, 141, 0);
}

.inline-message.info
{
    color: rgb(8, 88, 209);
    background-color: rgba(8, 88, 209, 0.2);
    border-left: 3px solid rgb(8, 88, 209);
}

#message
{
    align-self: center;
}
</style>