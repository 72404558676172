<template>

    <router-view />
    
</template>


<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    components: { },

    mounted () 
    {
        //console.log(process.env.NODE_ENV); // OUTPUT: production
    },
});
</script>


<style>
#app
{
    /* font-family: Avenir,
        Helvetica,
        Arial,
        sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgba(255, 255, 255, 0);
    scroll-behavior: smooth;

    --color-black: #333333;
    --color-darkgray: #57606f;
    --color-gray: #aab8c2;
    --color-blueish-white: #d8e1e8;
    --color-white: #f2f2f2;
    --color-highlight: #FF9900;

    --rgba-blueish-white: 216, 225, 232;
    --rgba-black: 51, 51, 51;
}
</style>
