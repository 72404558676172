<template>

    <div id="contact">

        <form id="contact-form" ref="contactForm" v-on:submit="(event) => { event.preventDefault(); }">
            <input type="text" name="name" id="name" placeholder="Name">
            <input type="email" name="email" id="email" placeholder="Deine E-Mail-Adresse">
            <textarea name="message" id="message">{{ this.prefill }}</textarea>

            <label for="consent">
                <input type="checkbox" name="consent" id="consent">
                <span>Du bestätigst, dass Du die Datenschutzerklärung gelesen hast und willigst
                    ein. Du kannst deine Einwilligung in die Verarbeitung deiner Daten und in meine Datenschutzerklärung
                    jederzeit widerrufen.
                </span>
            </label>

            <button class="primary-button" v-if="this.isSending === false" v-on:click="this.onSendButtonClick()">
                absenden
            </button>

            <div class="loading-spinner" v-if="this.isSending === true">
                <i class="fa-solid fa-spinner rotating"></i>
            </div>

            <div v-if="this.message != ''">
                <InlineMessage :message="this.message" :type="this.messageType" />
            </div>
        </form>

    </div>

</template>


<script lang="ts">
import { defineComponent } from 'vue';
import emailjs from '@emailjs/browser';
import { EMailValidator } from '@/libs/EMailValidator';
import InlineMessage from './InlineMessage.vue';

export default defineComponent({
    name: 'ContactForm',

    components: { InlineMessage },

    data ()
    {
        return {
            isSending: false,
            prefill: 'Hei Dominic, ja, ich möchte, dass du Kontakt mit mir aufnimmst, um das Angebot zu besprechen.',
            message: '',
            messageType: '',
        }
    },

    methods:
    {
        clearMessages (): void
        {
            this.message = '';
            this.messageType = '';
        },

        onSendButtonClick (): void
        {
            this.clearMessages();
            const email = (document.getElementById('email') as HTMLInputElement).value.trim();
            const emailValidator = new EMailValidator(email);
            const consent = (document.getElementById('consent') as HTMLInputElement).checked;
            const name = (document.getElementById('name') as HTMLInputElement).value;

            if (consent != true)
            {
                this.message = 'Bitte bestätige, dass du die Datenschutzerklärung gelesen hast und akzeptierst!';
                this.messageType = 'warning';
                return;
            }

            if (emailValidator.isValid() === false)
            {
                this.message = 'Ungültiges Format der E-Mail-Adresse!';
                this.messageType = 'warning';
                return;
            }

            if (name != '')
            {
                this.message = ' ';
                this.messageType = 'error';
                return;
            }

            this.sendEmail();
        },

        sendEmail (): void
        {
            this.isSending = true;

            emailjs
                .sendForm('service_onprzkb', 'template_rxrg4ig', this.$refs.contactForm, {
                    publicKey: 'ynLcKkuZo-uJgtNms',
                })
                .then(
                    () =>
                    {
                        this.message = 'Nachricht erfolgreich gesendet!';
                        this.messageType = 'success';
                        this.isSending = false;
                    },
                    (error) =>
                    {
                        this.message = 'Ein Fehler ist aufgetreten ("' + error.text + '")';
                        this.messageType = 'error';
                        this.isSending = false;
                    },
                );
        },
    }
});
</script>


<style scoped>
#contact
{
    padding: 12px;
    background-color: rgba(var(--rgba-blueish-white), 0.75);
    box-sizing: border-box;
    height: 100%;
}

#contact-form
{
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 12px;
}

textarea
{
    resize: none;
    flex-grow: 1;
    min-height: 15rem;
}

textarea,
#email
{
    font-family: Montserrat;
    font-size: 1rem;
}

#email
{
    padding: 10px;
}

#name
{
    visibility: collapse;
}

label
{
    font-family: Montserrat;
    font-size: 0.85rem;
}

.primary-button
{
    flex-grow: 0;
}

.loading-spinner
{
    color: var(--color-black);
    font-size: 2.5rem;
    text-align: center;
}
</style>