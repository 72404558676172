<template>

    <LinkFooter />

    <div id="home">

        <div class="page-content">

            <TitleHeader />

            <div class="two-sections">

                <div id="left-section">
                    <GalleryMasonry v-if="this.mode === ''" />
                    <ContactForm ref="contact" v-if="this.mode === 'mail'"/>
                </div>

                <div id="right-section">
                    <div class="text-area">
                        <h2>Was ist das Angebot?</h2>
                        <p>
                            Ich biete dir ein kostenloses Fotoshooting an, das dazu dient, deine Sichtbarkeit und
                            Außendarstellung zu verbessern.
                            Gemeinsam schaffen wir authentische Bilder, die deine Persönlichkeit und dein
                            Unternehmen im besten Licht präsentieren.
                            Das Angebot ist komplett unverbindlich – es entstehen keine Kosten für dich
                        </p>

                        <h2>Warum ist es kostenlos?</h2>
                        <p>
                            Ich glaube daran, dass gute Zusammenarbeit auf Vertrauen basiert. Dieses
                            Fotoshooting gibt uns die Möglichkeit, uns kennenzulernen, und dir die Chance, die
                            Wirkung meiner Fotografie direkt zu erleben.
                            So kannst du selbst erfahren, wie professionell erstellte Bilder deine Marke unterstützen
                            können, ohne direkt eine Verpflichtung einzugehen.
                        </p>

                        <h2>Bedingungen & Ablauf</h2>
                        <p>
                            <strong>Vorgespräch</strong>: In einem kurzen Gespräch klären wir, was dir wichtig ist und
                            welche Ziele
                            du hast. So kann ich sicherstellen, dass die Bilder perfekt zu dir passen.
                        </p>

                        <p>
                            <strong>Fotoshooting</strong>: Wir machen die Fotos ganz entspannt, im Stil, der zu deiner Marke
                            passt, und nehmen uns die Zeit, die wir brauchen.
                        </p>

                        <p>
                            <strong>Bildauswahl</strong>: Anschließend erhältst du eine Auswahl der besten Aufnahmen. Die finale
                            Entscheidung liegt bei dir, was du nutzen möchtest.
                        </p>

                        <p>
                            <strong>Ohne Verpflichtung</strong>: Du bist danach zu nichts verpflichtet – nutze einfach die Bilder,
                            die zu dir passen, und schau, wie sie deine Außendarstellung stärken.
                        </p>

                        <p>
                            <strong>Nutzung der Bilder</strong>: Mit deiner freundlichen Genehmigung darf ich die Bilder auch für
                            mein Portfolio verwenden. Das hilft mir, meine Arbeit authentisch darzustellen
                        </p>
                    </div>

                    <div id="buttons">
                        <button id="button-email" class="primary-button" v-if="this.mode === ''" v-on:click="this.openContactForm()">
                            <i class="fa-solid fa-envelope-open-text"></i>
                            Hinterlasse mir eine Nachricht
                        </button>

                        <button id="button-email-close" class="primary-button" v-if="this.mode === 'mail'" v-on:click="this.onExitContactForm()">
                            <i class="fa-solid fa-circle-xmark"></i>
                            Kontaktformular schließen
                        </button>

                        <button id="button-whatsapp" class="primary-button" v-on:click="this.openWhatsappLink()">
                            <i class="fa-brands fa-whatsapp"></i>
                            Schreib mir auf Whatsapp
                        </button>
                    </div>

                </div>

            </div>
        </div>
    </div>

</template>


<script lang="ts">
import { defineComponent, nextTick } from 'vue';
import TitleHeader from '@/components/TitleHeader.vue';
import LinkFooter from '@/components/LinkFooter.vue';
import GalleryMasonry from '@/components/GalleryMasonry.vue';
import ContactForm from '@/components/ContactForm.vue';

export default defineComponent({
    name: 'Homeview',

    components: { LinkFooter, GalleryMasonry, TitleHeader, ContactForm },

    data ()
    {
        return{
            mode: '',
        }
    },

    methods:
    {
        openWhatsappLink ():void
        {
            //location.href = 'https://wa.me/message/BRIWVGFTK6SQO1?src=qr';
            window.open('https://wa.me/message/BRIWVGFTK6SQO1?src=qr', '_blank');
        },

        async openContactForm ()
        {
            this.mode = 'mail';
            await nextTick();

            this.$refs.contact.clearMessages();
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        async onExitContactForm ()
        {
            this.mode = '';
            await nextTick();

            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
});
</script>


<style scoped>
#home
{
    width: 100%;
    background-image: url('@/assets/hintergrund.jpg');
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    box-sizing: border-box;
}

.page-content
{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    min-height: 100dvh;
    z-index: 2;
    /* margin-top: 10vh; */
}

.two-sections
{
    align-content: center;
    box-sizing: border-box;
    display: grid;
    gap: 2vh;
    padding: 2vh;
    grid-template-columns: 1fr;
    margin-bottom: 10dvh;
    margin-top: 0dvh;
    flex-grow: 1;
}

#left-section
{
    box-sizing: border-box;
    /* background-color: rgba(var(--rgba-blueish-white), 0.75); */
}

#right-section
{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.text-area
{
    box-sizing: border-box;
    color: var(--color-white);
    flex-grow: 1;
}

.text-area strong
{
    font-family: Montserrat-Bold;   
}

.text-area p
{
    font-family: Montserrat;
    font-size: 15px;
    font-weight: lighter;
    text-align: justify;
    margin-bottom: 1.5vh;
    margin-top: 1vh;
}

.portrait
{
    max-width: 200px;
}

#buttons
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2vh;
    margin-top: 2vh;
}

@media only screen and (min-width: 900px)
{
    .two-sections
    {
        grid-template-columns: 1fr 1fr;
        /* display: flex;
        flex-direction: row; */
        padding-left: 12%;
        padding-right: 12%;
        align-self: center;
        margin-top: 0dvh;
        margin-bottom: 8vh;
        gap: 5vh;
    }

    /* #left-section,
    #right-section
    {
        flex-grow: 1;
    } */

    .text-area p
    {
        font-size: 16px;
    }
}
</style>