<template>

    <div id="header">
        <span id="title"> {{ this.title }} </span>
        <span id="subtitle"> {{ this.subtitle }} </span>
    </div>

</template>


<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'TitleHeader',

    data ()
    {
        return {
            title: 'Sichtbarkeit auf höchstem Niveau - dein perfekter Einstieg in die Markenfotografie',
            subtitle: 'Dominic Mann Fotografie',
        }
    },
});
</script>


<style scoped>
#header
{
    box-sizing: border-box;
    color: white;
    width: 100%;
    text-align: center;
    padding-left: 2vh;
    padding-right: 2vh;
    margin-top: 3vh;
    margin-bottom: 0vh;
}

#title
{
    box-sizing: border-box;
    color: var(--color-highlight);
    font-family: Lora;
    display: block;
    font-size: 18px;
    letter-spacing: -1px;
    margin-bottom: 3vh;
}

#subtitle
{
    box-sizing: border-box;
    font-family: Lora;
    display: block;
    font-size: 14px;
}

@media only screen and (min-width: 900px)
{
    #header
    {
        margin-top: 1.5vh;
        margin-bottom: 1.5vh;
        padding-left: 0vh;
        padding-right: 0vh;
    }

    #title
    {
        font-size: 32px;
        letter-spacing: -2px;
        margin-bottom: 2vh;
    }

    #subtitle
    {
        font-size: 20px;
        /* margin-bottom: 15px; */
    }
}
</style>