import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './main.css';

const webapp = createApp(App);

webapp.use(router);
webapp.mount('#app');

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');
