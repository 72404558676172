// Maximum considers https://www.rfc-editor.org/rfc/rfc3696
const defMinLength = 8;
const defMaxLength = 254;

/**
 * Validates an email that was input by the user
 */
export class EMailValidator
{
    private email: string;
    private errors: Array<String>;
    private minLength: number;
    private maxLength: number;

    /**
     * 
     */
    constructor(email: string = '')
    {
        this.email = email.trim();
        this.minLength = defMinLength;
        this.maxLength = defMaxLength;
        this.errors = [];
    }


    /**
     * 
     * @param newValue 
     */
    public setMinLength (newValue: number): void
    {
        this.minLength = newValue;
    }

    /**
     * 
     * @param newValue 
     */
    public setMaxLength (newValue: number): void
    {
        this.maxLength = newValue;
    }

    /**
     * 
     * @returns 
     */
    public getMinLength (): number
    {
        return this.minLength;
    }

    /**
     * 
     * @returns 
     */
    public getMaxLength (): number
    {
        return this.maxLength;
    }


    /**
     * 
     * @returns 
     */
    public isValid (): boolean
    {
        if (this.isEmailTooShort() === true)
        {
            this.errors.push('validation.emailTooShort');
            return false;
        }

        if (this.isEmailTooLong() === true)
            {
                this.errors.push('validation.emailTooLong');
                return false;
            }

        if (this.isStructureValid() === false)
        {
            this.errors.push('validation.emailMalformed');
            return false;
        }

        return true;
    }


    /**
     * 
     * @returns 
     */
    public getError (): String
    {
        if (this.errors.length > 0)
        {
            return this.errors[0];
        }

        return 'unspecified error';
    }


    /**
     * 
     * @returns 
     */
    private isEmailTooShort (): boolean
    {
        if (this.email.trim().length === 0)
        {
            return false;
        }

        return this.email.length < this.minLength;
    }

    /**
     * 
     * @returns 
     */
    private isEmailTooLong (): boolean
    {
        return this.email.length > this.maxLength;
    }


    /**
     * 
     */
    private isStructureValid (): boolean
    {
        const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,63})+$/;
        const found = this.email.match(regex);

        if (found)
        {
            return true;
        }

        return false;
    }
}