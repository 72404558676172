<template>

    <div id="legals-footer">

        <div id="legals">
            <span class="legal-link hoverable"> 
                <RouterLink id="impressum" to="/impressum"> Impressum </RouterLink> 
            </span>
            <span class="legal-link hoverable"> 
                <RouterLink id="datenschutz" to="/datenschutz"> Datenschutz </RouterLink> 
            </span>
        </div>

        <div id="footer-secondary">
            <i class="fa-solid fa-laptop-code highlighted"></i> with <i class="fa-brands fa-vuejs highlighted"></i> by
            <span id="sum-link" class="hoverable"><a href="https://www.software-und-meer.de" target="_blank">Software & Meer</a></span>
        </div>
    </div>

</template>


<style scoped>
#legals-footer
{
    font-family: Montserrat-Bold;
    color: var(--color-white);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 2vh;
    padding-top: 4vh;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 30%, rgba(0, 0, 0, 1) 100%);
    /*background: linear-gradient(180deg, rgba(var(--rgba-black), 0) 0%, rgba(var(--rgba-black), 0.55) 20%, rgba(var(--rgba-black), 1) 100%);*/
    position: fixed;
    bottom: 0dvh;
    z-index: 1;
}

#footer-secondary
{
    justify-self: end;
    text-align: center;
    cursor: pointer;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    flex-grow: 1;
}

#footer-secondary span
{
    margin-left: 5px;
    word-spacing: 2px;
    letter-spacing: 2px;
}

#footer-secondary i,
#footer-secondary svg
{
    padding-left: 4px;
    padding-right: 4px;
}

#legals
{
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    letter-spacing: 2px;
    padding-bottom: 1vh;
}

#legals span
{
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    cursor: pointer;
}

#legals span:nth-child(even)
{
    border-left: 2px dotted var(--color-highlight);
}

#impressum,
#datenschutz,
#sum-link a
{
    color: unset;
    text-decoration: none;
    transition: color 0.4s ease-out;
}

#impressum:hover,
#datenschutz:hover,
#sum-link a:hover
{
    color: var(--color-highlight);
    transition: color 0.4s ease-out;
}


</style>